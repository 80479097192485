import React from 'react';
import {
  CircularProgress,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import config from '@configFile';
import { useGetTopDeals } from '@hooks/useTopDeals';
import colors from '@utils/colors';
import AddMailingList from '@components/AddMailingList';
import SummaryListDeal from '../SummaryListDeal';

// Create a new component called TopDeals which will list links to all the top deals
const TopDeals = () => {
  const { data: topDeals, isLoading } = useGetTopDeals();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!topDeals) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: 8
      }}
    >
      <Accordion
        defaultExpanded
        sx={{
          border: `1px solid ${colors.popular}`,
          borderRadius: '4px',
          background: colors.popular
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: 'white'
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: colors.popular,
            borderRadius: '4px'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold'
            }}
            variant="subtitle2"
          >
            Most Popular Deals of the Week
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: 'white'
          }}
        >
          {(topDeals || []).map((deal) => (
            <SummaryListDeal
              key={deal.ASIN}
              deal={deal}
              tag={config.AFFILIATE_TAGS.BEST_SELLER}
            />
          ))}
          <div style={{ margin: '30px 0' }}>
            <AddMailingList
              mailingListId={config.sendGrid.mailingLists.bestSellingTopDeals}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TopDeals;
