import React from 'react';
import _ from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { ButtonWrapper } from '@pages/Deals/components/DatabaseCard';
// import { maxImageHeightAndWidth } from './DatabaseCard/layoutSettings';
import classes from './DatabaseCard/styles';
import {
  imageDbStyles,
  imageMobileWrapperDbMobileStyles,
  imageMobileWrapperDbStyles
} from './DatabaseCard/components/CardImage/styles';

const SkeletonDatabaseCard = () => {
  return (
    <Card
      key="test"
      raised
      sx={{
        ...classes.cardRootDb,
        display: 'block !important'
      }}
    >
      <>
        <Box>
          <Box sx={classes.outerContentWrapper}>
            <Box position="relative" margin="0 auto" sx={classes.cardImage}>
              <Box
                sx={{
                  '@media (max-width: 400px)': {
                    display: 'flex'
                  },
                  ...imageMobileWrapperDbStyles
                }}
              >
                <Skeleton
                  component="div"
                  variant="rectangular"
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.11) !important',
                    marginTop: '8px',
                    '@media (min-width: 400px) and (max-width: 450px)': {
                      margin: '0px !important'
                    },
                    ...{ ...imageDbStyles, imageMobileWrapperDbMobileStyles }
                  }}
                />
              </Box>
            </Box>
            <CardContent
              sx={{
                ...classes.cardContent,
                ...classes.skeletonCardContent
              }}
            >
              <Skeleton
                sx={{
                  marginBottom: '4px'
                }}
                variant="rectangular"
                height={10}
                width="80%"
              />
              <Skeleton
                sx={{
                  marginBottom: '4px'
                }}
                variant="rectangular"
                height={16}
                width="95%"
              />
              <Skeleton
                sx={{
                  marginBottom: '4px'
                }}
                variant="rectangular"
                height={16}
                width="45%"
              />
              <Skeleton
                sx={{
                  marginBottom: '4px'
                }}
                variant="rectangular"
                width="40%"
              />
              <Skeleton
                sx={{
                  marginBottom: 6
                }}
                variant="rectangular"
                height={10}
                width="50%"
              />
              <Box marginTop="4px" display="flex">
                <Skeleton
                  variant="rounded"
                  height={20}
                  width={95}
                  sx={{
                    borderRadius: '16px',
                    marginRight: '4px',
                    display: 'inline-flex',
                    marginBottom: '4px'
                  }}
                />
                <Skeleton
                  variant="rounded"
                  height={20}
                  width={66}
                  sx={{
                    borderRadius: '16px',
                    display: 'inline-flex',
                    marginBottom: '4px'
                  }}
                />
              </Box>
            </CardContent>
          </Box>
          <CardActions sx={classes.actionButtons}>
            {_.times(3, (i) => (
              <ButtonWrapper
                key={i}
                button={
                  <Skeleton
                    sx={{
                      marginRight: '0px'
                    }}
                    variant="circular"
                    height={34}
                    width={34}
                  />
                }
                label={
                  <Skeleton
                    sx={{
                      marginRight: '0px'
                    }}
                    variant="rectangular"
                    height={10}
                    width={30}
                  />
                }
              />
            ))}
          </CardActions>
        </Box>
      </>
    </Card>
  );
};

export default SkeletonDatabaseCard;
