import React, { useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { logPostHogEvent } from '@utils/index';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSnackbar } from '@hooks/useSnackbar';
import { useSignUpModal } from '@hooks/useLoginModal';
import trackUse from '@utils/trackUse';
import { useToggleFavorite, useGetFavorites } from '@hooks/useFavorites';
import { iconButtonHoverRaiseStyle } from '@utils/styles';

const FavoriteButton: React.FC<{
  ASIN: string;
  finalPrice: number;
  size?: 'small' | 'medium' | 'large';
}> = ({ ASIN, finalPrice, size = 'small' }) => {
  const { showNonLoggedInModal } = useSignUpModal();
  const { data: user } = useGetUserData();
  const { data: userFavorites = [] } = useGetFavorites();
  const { showMessage } = useSnackbar();
  const [forceFavorite, setForceFavorite] = useState(false);
  const { mutate: toggleFavorite, isLoading: isFavoriting } =
    useToggleFavorite();
  const isFavorite = (userFavorites || []).includes(ASIN);

  const fontSize = size === 'small' ? 'medium' : 'inherit';

  const FavoriteItemComponent = FavoriteIcon;

  return (
    <>
      {user && (forceFavorite || (isFavorite && !forceFavorite)) && (
        <Tooltip title="Remove from favorites">
          <IconButton
            key="btn-fav"
            size={size}
            aria-label="Favorite item"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              if (isFavoriting) {
                return;
              }

              logPostHogEvent('remove-favorite-item', {
                value: `${ASIN} - ${finalPrice}`,
                type: 'CLICK'
              });

              trackUse({
                item: 'remove-favorite-item',
                value: `${ASIN} - ${finalPrice}`,
                type: 'CLICK'
              });

              setForceFavorite(false);

              toggleFavorite({ ASIN, finalPrice });

              showMessage('Removed from favorites', {
                undoAction: () => {
                  setForceFavorite(true);
                  toggleFavorite({ ASIN, finalPrice });
                }
              });
            }}
            sx={{
              '&:hover': {
                color: '#e02b50',
                opacity: 1,
                cursor: 'pointer'
              }
            }}
          >
            <FavoriteItemComponent
              key="fav-1"
              data-testid="isFavorite"
              sx={{
                color: '#e02b50',
                '&:hover': {
                  color: '#e02b50',
                  opacity: 1,
                  cursor: 'pointer'
                }
                /*
                opacity: 1,
                color: darkMode ? '#cf0b0d' : '#e02b50',
                verticalAlign: 'middle',
                transform: 'translate3d(0, 0, 0)',
        
                '&:hover': {
                  color: darkMode ? '#ffffffbf' : 'initial !important',
                  opacity: 0.3,
                  cursor: 'pointer'
                }
                */
              }}
              fontSize={fontSize}
            />
          </IconButton>
        </Tooltip>
      )}
      {user && !isFavorite && !forceFavorite && (
        <Tooltip title="Add to favorites">
          <IconButton
            key="btn-fav-no"
            size={size}
            aria-label="Favorite item"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              if (isFavoriting) {
                return;
              }

              trackUse({
                item: 'favorite-item',
                value: `${ASIN} - ${finalPrice}`,
                type: 'CLICK'
              });

              logPostHogEvent('favorite-item', {
                value: `${ASIN} - ${finalPrice}`,
                type: 'CLICK'
              });

              setForceFavorite(true);

              toggleFavorite({ ASIN, finalPrice });

              showMessage('Added to favorites', {
                undoAction: () => {
                  setForceFavorite(false);
                  toggleFavorite({ ASIN, finalPrice });
                }
              });
            }}
            sx={{
              ...iconButtonHoverRaiseStyle
            }}
          >
            <FavoriteBorderIcon
              data-testid="isNotFavorite"
              sx={{
                color: 'rbga(0, 0, 0, 0.87)',
                opacity: 0.3,
                verticalAlign: 'middle',
                transform: 'translate3d(0, 0, 0)',

                '&:hover': {
                  color: '#e02b50',
                  opacity: 1,
                  cursor: 'pointer'
                }
              }}
              fontSize={fontSize}
            />
          </IconButton>
        </Tooltip>
      )}
      {!user && (
        <Tooltip title="Add to favorites">
          <IconButton
            key="btn-fav-no-user"
            size={size}
            onClick={() => {
              showNonLoggedInModal({
                onLoginCBFn: () => {
                  trackUse({
                    item: 'non-loggedin-favorite-item-login-modal',
                    value: ASIN,
                    type: 'CLICK'
                  });

                  toggleFavorite({ ASIN, finalPrice });

                  logPostHogEvent('non-loggedin-favorite-item-login-modal', {
                    value: ASIN,
                    type: 'CLICK'
                  });
                },
                featureName: `toggle-favorite-${ASIN}`
              });
              trackUse({
                item: 'non-loggedin-favorite-item',
                value: ASIN,
                type: 'CLICK'
              });

              logPostHogEvent('non-loggedin-favorite-item', {
                value: ASIN,
                type: 'CLICK'
              });
            }}
            aria-label="Favorite item"
            sx={{
              ...iconButtonHoverRaiseStyle
            }}
          >
            <FavoriteBorderIcon
              key="fav-3"
              data-testid="no-user"
              sx={{
                opacity: 0.3,
                verticalAlign: 'middle',
                transform: 'translate3d(0, 0, 0)',

                '&:hover': {
                  color: '#e02b50',
                  opacity: 1,
                  cursor: 'pointer'
                },
                color: 'rbga(0, 0, 0, 0.87)',
                cursor: 'not-allowed'
              }}
              fontSize={fontSize}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default FavoriteButton;
