/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
  Typography,
  Card,
  Box,
  CardContent,
  IconButton,
  Tooltip
} from '@mui/material';
import { formatDistance } from 'date-fns';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useGetFavorites } from '@hooks/useFavorites';
import { CarouselRibbon } from '@components/Ribbon';
import config from '@configFile';
import { DealPostType, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import CouponChip from '@components/CouponChip';
import PromoFixedChip from '@components/PromoFixedChip';
import FavoriteButton from '@components/FavoriteButton';
import NewTooltip from '@components/NewTooltip';

import { MonetizingLink } from '@components/index';
import {
  parseTitle,
  getPostPrice,
  getProductLink,
  logPostHogEvent,
  getPercentageDiscountFromListPrice,
  getListPrice
} from '@utils/index';
import SubscribeSaveChip from '@components/SubscribeSaveChip';
import { iconButtonHoverRaiseStyle } from '@utils/styles';
import { dealsAction } from '../../../../actions';
import DealCardActions from '../DealCard/components/DealCardActions';
import PercentageDiscount from '../DealCard/components/PercentageDiscount';
import CardImage from './components/CardImage';
import classes from './styles';

interface DealCardProps extends DealPostType {
  onlyCoupons: boolean;
  toggleOnlyCoupons: () => void;
  tag?: string;
}

export const ButtonWrapper = ({
  button,
  label
}: {
  button: React.ReactElement;
  label: React.ReactNode;
}) => {
  return (
    <Box textAlign="center" display="block">
      <Box>{button}</Box>
      <Typography
        sx={{
          display: 'block',
          textAlign: 'center'
        }}
        variant="caption"
        component="div"
      >
        {label}
      </Typography>
    </Box>
  );
};

const DatabaseCard = (props: DealCardProps) => {
  const {
    title,
    postDate,
    ASIN,
    finalPrice,
    ss,
    couponPercentage,
    couponFixed,
    promoFixed,
    tag
  } = props;

  const affiliateTag = tag || config.AFFILIATE_TAGS.DEAL;
  const history = useHistory();
  const { data: userFavorites } = useGetFavorites();
  const dispatch = useDispatch();

  const link = getProductLink(ASIN, affiliateTag);

  const isFavorite = React.useMemo(() => {
    return ASIN && userFavorites?.length > 0
      ? (userFavorites || []).includes(ASIN)
      : false;
  }, [ASIN, userFavorites]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getCardChips = () => {
    const chips = [];

    if (couponPercentage || couponFixed) {
      chips.push(<CouponChip {...props} key="coupon-chip" />);
    }

    if (ss) {
      chips.push(<SubscribeSaveChip {...props} key="ss-chip" />);
    }

    if (promoFixed) {
      chips.push(<PromoFixedChip {...props} key="promo-chip" />);
    }

    if (chips.length > 0) {
      return (
        <Box
          sx={{
            marginBottom: '0.35em',
            marginTop: '0.35em',
            display: 'block',
            minHeight: '28px'
          }}
        >
          {chips}
        </Box>
      );
    }

    return null;
  };

  const renderNewTooltip = () => {
    return (
      <NewTooltip variant="caption" usePostDate deal={props} label="*New*" />
    );
  };

  const getPercentageDiscount = () => {
    const percentDiscount = getPercentageDiscountFromListPrice(props);
    if (percentDiscount === null || percentDiscount < 10) {
      return null;
    }

    return <PercentageDiscount {...props} isDatabaseCard />;
  };

  return (
    <Card
      key={ASIN}
      raised
      sx={{
        ...classes.cardRootDb,
        ...(props.isBestSeller ? classes.bestSellingBorderDB : {}),
        ...(isFavorite ? classes.favoriteBorderDB : {})
      }}
    >
      <Box position="relative" height="100%">
        <Box sx={classes.outerContentWrapper}>
          <MonetizingLink
            href={link}
            clickType="TEXT"
            tag={affiliateTag}
            item="deal-image-wrapper"
            sx={classes.cardImage}
          >
            {props.isBestSeller || isFavorite ? (
              <CarouselRibbon
                fontSize="11px"
                padding="3px 8px"
                dealSummary={props}
                opacity={1}
                onlyShowImportant
              />
            ) : null}
            <CardImage {...props} />
          </MonetizingLink>
          <CardContent sx={classes.cardContent}>
            <Typography sx={classes.mobileDate}>
              {renderNewTooltip()}
              {`Added by Sarah 
          ${formatDistance(new Date(postDate), new Date(), {
            addSuffix: true
          })}`}
            </Typography>
            <MonetizingLink
              href={link}
              clickType="TEXT"
              tag={affiliateTag}
              item="deal-card-title"
              sx={classes.cardText}
            >
              <Typography gutterBottom component="div" sx={classes.action}>
                {`${parseTitle(title)}`}
              </Typography>
            </MonetizingLink>
            <MonetizingLink
              href={link}
              clickType="TEXT"
              tag={affiliateTag}
              item="deal-card-content"
              sx={classes.cardText}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="span"
                sx={classes.action2Line}
              >
                {getPercentageDiscount()}
                {`${getPostPrice(props, false, {
                  showReg: false,
                  qualifier: 'from'
                })}`}
                <Typography
                  variant="caption"
                  component="span"
                  sx={classes.listPrice}
                >
                  {`${getListPrice(props)}`}
                </Typography>
              </Typography>
              <Typography
                variant="caption"
                component="div"
                style={{
                  fontSize: '11px',
                  fontWeight: 'boldest'
                }}
              >
                <OpenInNewIcon
                  sx={{
                    fontSize: '11px',
                    verticalAlign: 'text-top',
                    marginRight: '4px'
                  }}
                />
                Amazon
              </Typography>
            </MonetizingLink>
            {getCardChips()}
          </CardContent>
        </Box>
        <Box sx={classes.actionButtons}>
          <ButtonWrapper
            button={<FavoriteButton ASIN={ASIN} finalPrice={finalPrice} />}
            label="Favorite"
          />
          <ButtonWrapper
            button={
              <Tooltip title="More deal details and related deals">
                <IconButton
                  key="btn-summary"
                  // className={classes.actionButton}
                  size="small"
                  sx={{
                    ...iconButtonHoverRaiseStyle
                  }}
                  onClick={() => {
                    logPostHogEvent('details_click', {
                      ASIN: props.ASIN
                    });
                    dispatch({
                      type: DEALS_UPDATE_RECEIPT_DEAL,
                      receiptDeal: props
                    });
                    dispatch(dealsAction.getDealSummary(props.ASIN));
                    history.push(`/deal/${props.ASIN}`);
                  }}
                >
                  <OpenInFullIcon />
                </IconButton>
              </Tooltip>
            }
            label="Details"
          />
          <ButtonWrapper
            button={<DealCardActions {...props} buttonSize="small" />}
            label="More"
          />
        </Box>
      </Box>
    </Card>
  );
};

export default DatabaseCard;
