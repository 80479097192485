import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import classes from './styles';

const SkeletonInnerDealCard = () => {
  return (
    <>
      <Box sx={classes.imageWrapper}>
        <Box
          sx={{
            width: 140,
            height: 140,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '3px',
            margin: '0px auto 8px auto',
            paddingLeft: '4px'
          }}
        >
          <Skeleton
            component="div"
            variant="rectangular"
            sx={{
              ...classes.imageMobile,
              ...classes.skeletonImageMobile
            }}
            width={140}
            height={140}
          />
        </Box>
      </Box>
      <CardContent
        sx={{
          ...classes.cardContent,
          ...classes.skeletonCardContent
        }}
      >
        <Skeleton
          sx={{
            marginBottom: '4px'
          }}
          variant="rectangular"
          height={16}
        />
        <Skeleton
          sx={{
            marginBottom: '4px'
          }}
          variant="rectangular"
          height={16}
          width="75%"
        />
        <Skeleton
          sx={{
            marginBottom: '4px'
          }}
          variant="rectangular"
          width="40%"
        />
        <Skeleton
          sx={{
            marginBottom: '6px'
          }}
          variant="rectangular"
          height={14}
          width="60%"
        />
      </CardContent>
      <CardActions sx={classes.actionButtons}>
        <Skeleton
          sx={classes.skeletonTextActions}
          variant="rectangular"
          height={30}
          width={78}
        />
        <Skeleton
          sx={{
            marginRight: '0px'
          }}
          variant="circular"
          height={34}
          width={34}
        />
        <Skeleton
          sx={{
            marginRight: '0px'
          }}
          variant="circular"
          height={34}
          width={34}
        />
      </CardActions>
    </>
  );
};

const SkeletonDealCard = () => {
  return (
    <Card
      key="test"
      raised
      sx={{
        ...classes.cardRoot,
        display: 'block !important'
      }}
    >
      <SkeletonInnerDealCard />
    </Card>
  );
};

export default SkeletonDealCard;
